import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import TradeViewChart from "react-crypto-chart";
const Tradedetails = (props) => {
    let params = useParams();


    return (
        <div className={"threescroll casinoscroll coindetail"}>
            <div className={"gamespills"}>
                <Link className={"left_sub"} to={"#"}><img src={"/images/icons/leftnav/dashboard.png"} /> Trade</Link>
                <Link className={"left_sub"} to={"/staking/"}><img src={"/images/icons/leftnav/stake.png"} /> Staking</Link>
                <Link className={"left_sub"} to={"#"}><img src={"/images/icons/leftnav/suitcase.png"} /> My Wallet</Link>


            </div>

            <div className={" row cat_header"}>

                <div className={"w100 row left25"}>
                 <div className={"col-md-3 col-sm-6"}>
                     <div className="w100 row m8 greybg">
                         <div className="col-md-2"><img className="w35" src="/images/icons/bitcoin.png" /></div>
                         <div className="col-md-10">
                             <div className="left_tp">Bitbet BTT</div>
                             <div className="left_bt">$0.11826 <span>-0.29%</span></div>
                         </div>
                     </div>
                 </div>
                 <div className={"col-md-9 col-sm-12 pr0"}>
                     <div className="w100 row m8 greybg">
                         <div className="col-md-3">
                             <div className="left_tp">Market Price</div>
                             <div className="price_market">$0.11826 <span>9.45%</span></div>
                         </div>
                         <div className="col-md-2">
                             <div className="left_tp">Balance</div>
                             <div className="left_bt">$0.00</div>
                         </div>
                         <div className="col-md-3">
                             <div className="left_tp">Stake Pools</div>
                             <div className="left_bt">233,45,255</div>
                         </div>
                         <div className="col-md-4">
                             <div className="left_tp">Pool Value</div>
                             <div className="left_bt">$45,11,826</div>
                         </div>
                     </div>

                 </div>

                </div>

                <div className={"chart_area w100 row"}>
                    <div className={"chart-col col-md-8 col-sm-12"}>
                        <div className="parent">
                            <TradeViewChart
                                interval="1d"
                                containerStyle={{
                                    minHeight: "370px",
                                    minWidth: "620px",
                                    marginBottom: "30px"
                                }}
                                chartLayout={{
                                    layout: {
                                        backgroundColor: "#1F2330",
                                        textColor: "#eee"
                                    },
                                    grid: {
                                        vertLines: {
                                            color: "#1F2330"
                                            // style: LineStyle.SparseDotted,
                                        },
                                        horzLines: {
                                            color: "#1F2330"
                                            // style: LineStyle.SparseDotted,
                                        }
                                    },
                                    priceScale: {
                                        borderColor: "#485c7b"
                                    },
                                    timeScale: {
                                        borderColor: "#485c7b",
                                        timeVisible: true,
                                        secondsVisible: false
                                    }
                                }}
                                candleStickConfig={{
                                    upColor: "green",
                                    downColor: "red",
                                    borderDownColor: "transparent",
                                    borderUpColor: "transparent",
                                    wickDownColor: "gray",
                                    wickUpColor: "gray"
                                }}
                                pair="ETHUSDT"
                            />
                        </div>
                    </div>
                    <div className={"mobile_absolute col-md-4 col-sm-12"}>
                        <div className={"buy_sell_box"}>

                            <div className={"bs_buttons"}>
                             <button className={"bs_buy"}>BUY</button>
                             <button className={"bs_sell"}>SELL</button>
                            </div>
                            <div className={"pr_input_data"}><div>BTT</div></div>
                            <div className={"token_input"}>
                                <img className="tinp_img w35" src="/images/icons/bitcoin.png" />
                                <input type={"text"} className={"token_i"}/>
                                <button className={"token_max"}>MAX</button>
                            </div>
                            <div className={"price_input"}>
                                <div className={"pr_input_data"}><div>Price</div><span>BTT</span></div>
                                <input value={"0.121.12"} type={"text"} className={"token_p"}/>
                            </div>
                            <div className={"cost_est"}>
                                <div className={"tk_cost"}>Cost:</div>
                                <div className={"tk_est"}>Estimate:</div>
                            </div>
                            <button className=" w100 mt-20 btn tbt buy-btn">LOGIN TO CONTINUE</button>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    );
}
export  default Tradedetails;
