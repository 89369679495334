import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Exchangescroller = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        arrows:true,
        autoplay:false,
        margin:10,
        autoplaySpeed:2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [1,2,3,4,5,6,7,8]
    let coins = [
        {
            id:1,
            type:"BTC",
            price:"25.254.20",
            status:"high"
        },
        {
            id:2,
            type:"ETH",
            price:"1.593.12",
            status:"low"
        },
        {
            id:3,
            type:"BNB",
            price:"212.85",
            status:"high"
        },
        {
            id:4,
            type:"APT",
            price:"5.29",
            status:"high"
        },
        {
            id:5,
            type:"BCH",
            price:"216.254",
            status:"low"
        },
        {
            id:6,
            type:"ADA",
            price:"0.25",
            status:"high"
        },
        {
            id:7,
            type:"APE",
            price:"1.1189",
            status:"low"
        }
        ,
        {
            id:8,
            type:"ARB",
            price:"0.8295",
            status:"high"
        }
        ,
        {
            id:9,
            type:"DOGE",
            price:"0.0606",
            status:"high"
        }
        ,
        {
            id:10,
            type:"LINK",
            price:"7.338",
            status:"low"
        }
        ,
        {
            id:11,
            type:"LTC",
            price:"63.884",
            status:"low"
        }
        ,
        {
            id:12,
            type:"TRX",
            price:"0.084",
            status:"high"
        }
        ,
        {
            id:13,
            type:"UNIBOT",
            price:"54.883",
            status:"high"
        }
    ]
    return (
        <div className={"threescroll cryptoscroll"}>
            <div className={"no_margin row"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/casino.png"} /> CRYPTO FUTURES
                   </div>
                <div className={"tvolume"}>Total 24h Volume: <span>$450M </span></div>
                <div className={"ttrade"}>Trade</div>
            </div>

            <Slider {...settings}>
                {coins.map((item,index) => {
                    return (
                        <div className="slidebox">
                            <div className="row column">
                                <a href={"/trade/btt"}>
                                <div className={"row m8"}>
                                    <div className={"col-md-3"}>
                                        <img className={"w35"} src={"/images/coins/"+item.type+".png"} />
                                    </div>
                                    <div className={"col-md-9"}>
                                        <div className={"left_tp"}>
                                            {item.price}
                                        </div>
                                        <div className={"left_bt"}>
                                           <span>-0.29%</span>
                                        </div>
                                    </div>

                                </div>
                                </a>
                            </div>
                            <a href={"/trade/btt"}> <div className={"exvolume"}>{item.type}</div></a>
                            <a href={"/trade/btt"}>
                            <div className={"excenter"}>
                                <div className={"exvol"}>24h vol:<span>$897M</span></div>
                                <div className={"exvol"}>Leverage:<span>50X</span></div>
                            </div>
                            </a>

                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Exchangescroller;
