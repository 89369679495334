import React, {useState} from 'react';
import Header from '../components/site/Header';
import {useSelector} from "react-redux";
import Nftdetail from "../components/site/Nftdetail";

export default function Nft (currentAppLocale) {
    const allData = useSelector((state) => state.general);
    const [cpp, setCpp] = useState([]);
    return (
        <>
            <div className="main mains gamedetail">
                <Header lang={currentAppLocale} />
                <Nftdetail lang={currentAppLocale} />
            </div>




        </>
    );

}
