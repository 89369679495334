import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
const Nftdetails = (props) => {
     let params = useParams();

    let slides = [
        {
            id:0,
            type:"Rollbot #1",
            price:"50",
            image:"1.webp"
        },
        {
            id:1,
            type:"Rollbot #1",
            price:"50",
            image:"1.webp"
        },
        {
            id:2,
            type:"Sport Rollbot #21",
            price:"47",
            image:"2.webp"
        },
        {
            id:3,
            type:"Bored Ape #1",
            price:"25",
            image:"3.avif"
        },
        {
            id:4,
            type:"Superlative Ape #1",
            price:"98",
            image:"4.avif"
        },
        {
            id:5,
            type:"League of Legends",
            price:"20",
            image:"5.webp"
        },
        {
            id:6,
            type:"Invisible Friends",
            price:"45",
            image:"6.webp"
        },
        {
            id:7,
            type:"Superlative Ape #20",
            price:"42",
            image:"7.avif"
        }
    ]
    let item = slides[params.item]

    return (
        <div className={"threescroll casinoscroll nftdetail"}>
            <div className={"gamespills back_bt"}>
                <Link className={"left_sub"} to={"/nfts"}><img src={"/images/icons/back.png"} /> Back</Link>



            </div>
            <div className={"no_margin row cat_header"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/nft.png"} /> NFT Details
                </div>



            </div>
            <div className={"row"}>
               <div className={"col-md-6 col-sm-12 col-xs-12"}>
                   <img className={"nft_detail_image"} src={"/images/nfts/"+item.image} />
               </div>
                <div className={"col-md-6 col-sm-12 col-xs-12"}>
                    <div className={"item_group"}>Category: {item.type} </div>
                    <div className={"item_title"}>{item.type}</div>
                    <div className={"opensea"}><a href={"https://opensea.com"}>Opensea</a></div>
                    <div className={"created"}><div className={"crby"}>Created By:</div><div className={"creator"}>Ape Club</div> </div>
                    <div className={"item_desc"}>
                        {item.type} - Meet The Bitebet Club's NFTs- A collection of 10,333 Party  with great
                        ambitions and a good sense of style.
                        Meet The Bitebet Club's NFTs- A collection of 10,333 Party  with great
                        ambitions and a good sense of style.
                        Meet The Bitebet Club's NFTs- A collection of 10,333 Party  with great
                        ambitions and a good sense of style.
                    </div>
                    <div className={"nft_prc"}>
                    <div className="nft_price">
                        <div>$15.7</div>
                        <div>$40.1</div>
                        <div>$103</div>
                    </div>
                    <div className="nft_palette" > </div>
                    </div>

                </div>
                <div className={"trait_header"}>Traits</div>
                <div className={"traits"}>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>SKIN</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>CHARCOAL</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>FUSION</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>NOT FUSED
                        </div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>MOUTH</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>SMILE</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>HAT</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>PUNK</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>EYES</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>GREEN</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>Earrings</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>NONE</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>Clothes</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>Tshirt</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>Background</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>RED</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>NOSE</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>YES</div>
                    </div>
                    <div className={"trait-item"}>
                        <div className={"trait_title"}>
                            <div className={"tr_title"}>Mouth</div>
                            <div className={"t_span"}>356</div>
                        </div>
                        <div className={"trait_desc"}>SMILE</div>
                    </div>

                </div>

            </div>

        </div>

    );
}
export  default Nftdetails;
