import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {deleteOdd, userBalance,deleteCoupons,mainCoupon} from "../../actions/general";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import jwtDecode from "jwt-decode";
import {setAuthorizationToken} from "../../helpers/setAuthorizationToken";
import {Link} from "react-router-dom";
import Ads from "./Ads";
import Slider from "react-slick";

const Slip = (props) => {
    const allData = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const [warn, setWarn] = useState(false);
    const [oddata, setOddata] = useState([]);
    const [tutar, setTutar] = useState(0);
    const [player, setPlayer] = useState(null);
    const [cpp,setCpp] = useState([])
    const [maincoupon,setMaincoupon] = useState([])
    const [mainbets,setMainbets] = useState([])
    const [cp, setCp] = useState({
        "img":"",
        "title":"",
        "desc":""
    });
    useEffect(() => {
        setOddata(allData.odddata);
        Coupons();
    }, [oddata]);
    const Coupons = async () => {
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
            axios.post(
                WEB_CONFIG.server + '/api/sport/dailycoupons',
                jwt_Token_decoded,
                {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {

                    setCpp(response.data);
                })
                .catch(error => {
                    console.log(error.data)
                });


        }

    }
    const deleteOdds = (order) => {
        dispatch(deleteOdd(order,allData.odddata[order]));
    }
    const deleteCoupon = () => {

        dispatch(deleteCoupons());
        setWarn(false);
    }
    const renderItem = (odd,index) => {

        const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.id);
        let oynanmis="passive";
        if(oynamakontrol.length>0) {  oynanmis='active'; }
        return (
            <div key={odd.id} className={"slip_match"}>
                <div onClick={() => deleteOdds(odd.id)} className={"slip_cancel "+oynanmis}><img src={"/images/icons/cancel.png"} /></div>
                <div className={"slip_team"}>{odd.first} - {odd.second}</div>
                <div className={"slip_odd"}>{props.lang["bet_odd"]}</div>
                <div className={"slip_result"}>{odd.selected} <span className={"right_align"}>{odd.odd}</span></div>
            </div>
        );
    }
    const saveodd = async () => {
      if(allData.userbalance) {
        if(tutar>0) {
            let data = {
                "user":allData.userdata,
                "odds":allData.odddata,
                "tutar":tutar,
                "toplam_oran":allData.oddtoplam
            }


            axios.post(
                WEB_CONFIG.server+'/api/sport/addcoupon',
                data,
                { headers: { 'Content-Type': 'application/json' }}
            )
                .then(response =>{
                    console.log(response.data);
                    setCp(response.data[1]);
                    dispatch(mainCoupon(response.data[0]))

                    axios.post(
                        WEB_CONFIG.server+'/api/sport/balance',
                        allData.userdata,
                        { headers: { 'Content-Type': 'application/json' }}
                    )
                        .then(response =>{

                            dispatch(userBalance(response.data.balance));
                            setWarn(true);
                        })
                        .catch(error => {
                            console.log(error.data)
                        });
                    setTimeout(function(){
                        setCp({
                            "img":"",
                            "title":"",
                            "desc":""
                        });
                    },3000);
                })
                .catch(error => {
                    // console.log(error.data)
                });





        }
        else {

            let scp = {
                "img":"error.png",
                "title":"Hata",
                "desc":"Lütfen Bir tutar giriniz"
            }
            setCp(scp);
            setTimeout(function(){
                setCp({
                    "img":"",
                    "title":"",
                    "desc":""
                });
            },3000);
        }
      } else {

        let scp = {
            "img":"error.png",
            "title":"Hata",
            "desc":"Lütfen Önce Giriş Yapınız"
        }
        setCp(scp);
        setTimeout(function(){
            setCp({
                "img":"",
                "title":"",
                "desc":""
            });
        },3000);
      }

    }
    const maincouponset = async (item) => {
        let detail = {
            "id":item.coupon_id
        }
        await   axios.post(
            WEB_CONFIG.server+'/api/sport/coupondetails',
            detail,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{

                setMainbets(response.data[1]);
                setMaincoupon(response.data[0]);
            })
            .catch(error => {
                console.log(error.data)
            });

    }

    let coins = [
        {
            id:1,
            type:"BTC",
            price:"25.254.20",
            status:"high"
        },
        {
            id:2,
            type:"ETH",
            price:"1.593.12",
            status:"low"
        },
        {
            id:3,
            type:"BNB",
            price:"212.85",
            status:"high"
        },
        {
            id:4,
            type:"APT",
            price:"5.29",
            status:"high"
        },
        {
            id:5,
            type:"BCH",
            price:"216.254",
            status:"low"
        },
        {
            id:6,
            type:"ADA",
            price:"0.25",
            status:"high"
        },
        {
            id:7,
            type:"APE",
            price:"1.1189",
            status:"low"
        }
        ,
        {
            id:8,
            type:"ARB",
            price:"0.8295",
            status:"high"
        }
        ,
        {
            id:9,
            type:"DOGE",
            price:"0.0606",
            status:"high"
        }
        ,
        {
            id:10,
            type:"LINK",
            price:"7.338",
            status:"low"
        }
        ,
        {
            id:11,
            type:"LTC",
            price:"63.884",
            status:"low"
        }
        ,
        {
            id:12,
            type:"TRX",
            price:"0.084",
            status:"high"
        }
        ,
        {
            id:13,
            type:"UNIBOT",
            price:"54.883",
            status:"high"
        }
    ]
    return (
        <div className={"right_nav"}>
        <div className={"slip"}>
            <div className={"slip_title"}><img alt={"coupon"} src={"/images/icons/kupon.png"} /> {props.lang["bet_coupon"]}</div>
            <div className={"slip_content"}>
                <div className={"col-md-12 btmz"}><button onClick={()=>deleteCoupon()} className={"btn btn_temizle"}>{props.lang["clear_coupon"]}</button></div>
                {cp.title && (
                    <div className={"coupon_alert"}>
                        <div className={"cp_img"}><img src={"/images/system/"+cp.img} alt={"warning"}/></div>
                        <div className={"cp_title"}>{cp.title}</div>
                        <div className={"cp_desc"}>{cp.desc}</div>
                    </div>
                )}
                {warn ===false ? (
                    <>
            {allData.odddata ?
                    Object.values(allData.odddata).map((odd,index) => (renderItem(odd,index)  )):(<>{props.lang["coupon_empty"]}</>)}
                ,                <div className={"slip_sub"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>X:</div>
                    <div className={"col-md-6 text-right"}>{allData.oddtoplam}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-4"}>{props.lang["amount"]}:</div>
                    <div className={"col-md-8 pr0 text-right"}>
                        <input onChange={(e) => setTutar(e.target.value)} id={"s_input"} type={"text"} className={"slip_input"}  value={tutar || ""} />
                    </div>

                </div>
                <div className={"row"}>
                    <div className={"col-md-6"}>{props.lang["player"]}:</div>
                    <div className={"col-md-6 text-right"}><input  onChange={(e) => setPlayer(e.target.value)} value={player || ""}  type={"text"} className={"slip_input2"} /></div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-6"}>{props.lang["winning_amount"]}:</div>
                    <div className={"col-md-6 text-right"}>{tutar?(allData.oddtoplam*tutar).toFixed(2):allData.oddtoplam} </div>
                </div>
                <button onClick={() => saveodd()}  className={"btn btn_rez"}>{props.lang["order_now"]}</button>
            </div>
                    </>  ):(
                <>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <button onClick={() => window.print()}  className={"btn btn_rez after_coupon"}>{props.lang["print"]}</button>
                            <button onClick={() => deleteCoupon()}  className={"btn btn_rez after_coupon"}>{props.lang["withdraw_request"]}</button>
                        </div>
                    </div>
                </>
                )}
                {allData.userbalance && (
                    <>
                        {maincoupon.price && (

                    <div className={"print_slip "}>
                        <div className={"daily_title"}>{props.lang["slip_detail"]}
                            <button onClick={()=>setMaincoupon([])} className={"cls"}>X</button>
                        </div>
                    <div className={"black_area"}>
                        <div className={"row"}>
                            <div className={"col-md-8"}>{props.lang["coupon_id"]}:</div>
                            <div className={"col-md-4"}>{maincoupon.coupon_id}</div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-8"}>{props.lang["amount"]}:</div>
                            <div className={"col-md-4"}>{maincoupon.price}</div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-8"}>{props.lang["possible_win"]}:</div>
                            <div className={"col-md-4"}>{maincoupon.total}</div>
                        </div>
                    </div>
                        <div className={"brown_area"}>
                            {mainbets.length>0 && mainbets.map((item,index) => {
                                return (

                                    <div className={"main_bets"}>
                                        <div className={"row"}>
                                            <div className={"col-md-8"}>{item.date}</div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-md-8"}>{item.first} - {item.second} </div>
                                            <div className={"col-md-4"}>{item.matchresult?item.matchresult:'-'}</div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-md-8"}>{item.selected}</div>

                                        </div>

                                    </div>
                                );
                            })}

                        </div>

                        <button className={"btn btn_print"}>{props.lang["print_slip"]}</button>
                        <div className={"cah_out_area row"}>
                           <div className={"col-md-6 cash_left"}>{maincoupon.price-((maincoupon.price/100)*10)} DNR</div>
                            <div className={"col-md-6"}><button className={"cash_out"}>{props.lang["cash_out"]}</button></div>
                        </div>

                    </div>

                        )}
                <div className={"daily_coupons"}>
                    <div className={"daily_title"}>{props.lang["latest_coupons"]}</div>
                {cpp &&
                cpp.map((item, index) => {
                    return (
                           <>

                            <div onClick={()=>maincouponset(item)} className={"daily_table row"}>
                                <div className={"col-md-4"}>{item.time.split(":")[0]}:{item.time.split(":")[1]}</div>
                                <div className={"col-md-4"}>{item.total_amount}</div>
                                <div className={"col-md-4"}>{item.winstatus?item.winstatus:item.status}
                                    <Link className={"slip_link"} to={"/coupon_detail/"+item.coupon_id}><img src={"/images/icons/right_arrow.gif"}/> </Link>
                                </div>
                            </div>
                           </>

                    );
                })
                }

                </div>
                    </>
                )}


            </div>

        </div>
            <div className={"mprc"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/casino.png"} /> MARKET PRICES
                </div>
                <div className={"ptsearch"}>
                    <input type={"text"} placeholder={"Search"} className={"ptsinput"} />
                    <div className={"ptsicon"}><img src={"/images/system/ptsearch.png"} /></div>
                </div>

                {coins.map((item,index) => {
                    return (
                        <a href={"/trade/"+item.type}>
                        <div className={"row market_logs"}>

                            <div className={"col-md-2"}><img className={"mpicon"} src={"/images/coins/"+item.type+".png"}/>
                            </div>
                            <div className={"col-md-4 mptitle"}>{item.type}</div>
                            <div className={"col-md-3 mprice"}>{item.price}</div>
                            <div className={"col-md-3"}><img className={"mphl"} src={"/images/data/"+item.status+".png"}/></div>

                        </div>
                        </a>
                    )
                })}

            </div>




            <Ads/>
        </div>
    );
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Slip);
