import React, {useEffect} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Leftnav from "../components/user/Leftnav";
import {useSelector} from "react-redux";
import axios from "axios";
import {WEB_CONFIG} from "../config/Static";
import jwtDecode from "jwt-decode";

export default function Register (currentAppLocale) {
    const lang = currentAppLocale.messages;
    const allData = useSelector((state) => state.general);
    const [panel,setPanel]= React.useState(false)
    const [editpanel,setEditpanel]= React.useState(false)
    const [edituser,setEdituser]= React.useState([])
    const [cp,setCp]= React.useState(false)
    const [warn,setWarn]= React.useState({
        status: "",
        message:""
    })
    const [userform,setUserForm]= React.useState({})
    const [usereditform,setUsereditForm]= React.useState({})
    const addUser = () => {
        let userdata = userform;

        axios.post(
            WEB_CONFIG.server + '/api/user/create',
            userdata,
            {headers: {'Content-Type': 'application/json'}}
        )
            .then(response => {

                setWarn(response.data);
                setTimeout(function(){
                    setWarn({})
                    setUserForm({})
                },3000)
            })
            .catch(error => {
                console.log(error.data)
            });
    }
    const setForm = (input,name) => {

        let key = name.toString()
        let val = input
        let obj  = {}
        obj[key] = val
        setUserForm({
            ...userform,
            [key]:input
        })

    }


    return (
        <div className="main">
            <Header lang={currentAppLocale} />
            <Topnav lang={currentAppLocale}/>
            <div className="main">
                <div className={"container"}>
                <div className={"row"}>

                    <div className={"pt-40 col-md-12  p0"}>


                        <div className={"row"}>
                            {warn.status && (
                                <div className={"warning"}>
                                    <h3>{warn.status}</h3>
                                    <p>{warn.message}</p>
                                </div>
                            )}
                            <div className={"col-md-4 p0"}>
                                <img className={"w100"} src={"/images/bans/reg_banner.png"} />

                            </div>
                            <div className={"col-md-8 p0"}>


                                    <div className={"reg_usr"}>


                                        <input value={userform.firstName || ""} onChange={(e) =>setForm(e.target.value,"firstName")} placeholder={lang['name']} type={"text"} />
                                        <input value={userform.lastName || ""} onChange={(e) =>setForm(e.target.value,"lastName")} placeholder={lang['surname']} type={"text"} />
                                        <input value={userform.email || ""} onChange={(e) =>setForm(e.target.value,"email")} placeholder={lang['username']} type={"text"} />
                                        <input value={userform.password || ""} onChange={(e) =>setForm(e.target.value,"password")} placeholder={lang['password']} type={"password"} />
                                        <input value={userform.ref || ""}  onChange={(e) =>setForm(e.target.value,"ref")} placeholder={lang['ref']} type={"text"} />
                                        <input value={userform.phone || ""} onChange={(e) =>setForm(e.target.value,"phone")} placeholder={lang['phone']} type={"text"} />
                                        <button  onClick={()=>{addUser()}} className={"btn add_user_btn"}>{lang['add_user']}</button>
                                    </div>






                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </div>
        </div>
    );

}

