import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Nftscroll = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        arrows:true,
        autoplay:false,
        margin:10,
        autoplaySpeed:2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [
        {
            id:1,
            type:"Rollbot #1",
            price:"50",
            image:"1.webp"
        },
        {
            id:2,
            type:"Sport #21",
            price:"47",
            image:"2.webp"
        },
        {
            id:3,
            type:"Bored Ape #1",
            price:"25",
            image:"3.avif"
        },
        {
            id:4,
            type:"Superlative Ape #1",
            price:"98",
            image:"4.avif"
        },
        {
            id:5,
            type:"League of Legends",
            price:"20",
            image:"5.webp"
        },
        {
            id:1,
            type:"Invisible Friends",
            price:"45",
            image:"6.webp"
        },
        {
            id:1,
            type:"Superlative Ape #20",
            price:"42",
            image:"7.avif"
        }
    ]
    let slidez = [1,2,3,4,5,6,7,8]

    return (
        <div className={"threescroll nftscroll"}>
            <div className={"no_margin row"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/casino.png"} /> NFT LOOTBOXES
                </div>

                <div className={"ttrade"}>View All</div>
            </div>

            <Slider {...settings}>
                {slides.map((item,index) => {
                    return (
                        <div className="slidebox">
                            <div className="row column">
                                <div className={"row m8"}>
                                    <div className={"col-md-12"}>
                                  <a href={"/nft/"+item.id}>    <img className={"nftimg"} src={"/images/nfts/"+item.image} /></a>
                                    </div>


                                </div>
                            </div>
                            <div className={"exvolume"}>{item.type}</div>
                            <div className={"excenter"}>
                                <div className={"exvol"}>{item.type}<span>20 Items</span></div>
                                <a href={"/nft/"+item.id}>      <button className="w80 mt-20 btn btn-danger">OPEN FOR {item.price}</button></a>
                            </div>

                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Nftscroll;
