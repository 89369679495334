import React, {useState} from 'react';
import Header from '../components/site/Header';
import Topnav from '../components/site/Topnav';
import Homematches from "../components/site/Homematches";
import {useSelector} from "react-redux";

export default function Home (currentAppLocale) {
    const allData = useSelector((state) => state.general);
    const [cpp, setCpp] = useState([]);
        return (
            <>
            <div className="main mains homemain">
            <Header lang={currentAppLocale} />

            <Homematches lang={currentAppLocale} />
              </div>
                <div className={"p_coupon"}>
                    <div className={"grey_box"}>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}>{currentAppLocale["player"]} :</div>
                            <div className={"col-md-6 align_right"}>user_id}</div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale["ticket_no"]} :</div>
                            <div className={"col-md-6 align_right"}>coupon_id}</div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale["date"]} :</div>
                            <div className={"col-md-6 align_right"}>date}</div>
                        </div>
                    </div>


                    {cpp &&
                    cpp.map((coupon,index) => {
                        return (
                            <div className={"white_box"}>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}>{currentAppLocale["football"]} :</div>
                                    <div className={"col-md-6 align_right"}>{coupon.date}
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}>{coupon.first} - {coupon.second}</div>
                                    <div className={"col-md-6 align_right"}> </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}> {currentAppLocale["guess"]}: {coupon.selected}</div>
                                    <div className={"col-md-6 align_right"}>{coupon.odd}</div>
                                </div>
                            </div>
                        )
                    })  }
                    <div className={"grey_box"}>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale["amount"]} :	</div>
                            <div className={"col-md-6 align_right"}>price
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale["odds"]} :</div>
                            <div className={"col-md-6 align_right"}>total_amount</div>


                        </div>
                        <div className={"row"}>
                            <div className={"bigp"}>DNR total}</div>
                        </div>

                    </div>

                </div>
                 <div className={"p_coupon"}>
                    {
                        allData.maincoupon && (


                            <div className={"grey_box"}>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}>{currentAppLocale.messages["player"]} :</div>
                                    <div className={"col-md-6 align_right"}>{allData.maincoupon.user_id}</div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}> {currentAppLocale.messages["ticket_no"]} :</div>
                                    <div className={"col-md-6 align_right"}>{allData.maincoupon.coupon_id}</div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}> {currentAppLocale.messages["date"]} :</div>
                                    <div className={"col-md-6 align_right"}>{allData.maincoupon.date}}</div>
                                </div>
                            </div>
                        )
                    }

                    { allData.maincoupon &&
                    allData.odddata.map((coupon,index) => {
                        return (
                            <div className={"white_box"}>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}>{currentAppLocale.messages["football"]} :</div>
                                    <div className={"col-md-6 align_right"}>{coupon.date}
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}>{coupon.first} - {coupon.second}</div>
                                    <div className={"col-md-6 align_right"}> </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-6 left"}> {currentAppLocale.messages["guess"]}: {coupon.selected}</div>
                                    <div className={"col-md-6 align_right"}>{coupon.odd}</div>
                                </div>
                            </div>
                        )
                    })  }
                    <div className={"grey_box"}>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale.messages["amount"]} :	</div>
                            <div className={"col-md-6 align_right"}>{allData.maincoupon.price}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-6 left"}> {currentAppLocale.messages["odds"]} :</div>
                            <div className={"col-md-6 align_right"}>{allData.maincoupon.total_amount}</div>


                        </div>
                        <div className={"row"}>
                            <div className={"bigp"}>DNR {allData.maincoupon.total}</div>
                        </div>

                    </div>
                </div>
                </>
        );

}
