import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Threescroller = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        arrows:true,
        autoplay:false,
        margin:10,
        autoplaySpeed:2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [1,2,3,4,5,6]
    let news = [
        {
            id:1,
            title:"Bitbet Coin (BTT)",
            desc:"Trade BTT, Bitbet's deflationary utility token that unlocks exclusive features.",
            subtitle:"Bitbet Coin BTT",
            subtitle2:"$0.118926"
        },
        {
            id:2,
            title:"Play Casino Games",
            desc:"Play famous Casino Games in bitbet, with usd or our tokens. You can also exchange your tokens to play games ",
            subtitle:"Play Games with Crypto",
            subtitle2:"Join us Now"
        },
        {
            id:3,
            title:"Bet Sport Games",
            desc:" Play All football leagues matches with best odds and with crypto currencies... ",
            subtitle:"Bet with crypto",
            subtitle2:"See our sportsbook page"
        },
        {
            id:4,
            title:"Bitbet Coin (BTT)",
            desc:"Trade BTT, Bitbet's deflationary utility token that unlocks exclusive features.",
            subtitle:"Bitbet Coin BTT",
            subtitle2:"$0.118926"
        }
            ]
    return (
        <div className={"threescroll"}>
            <div className={"scroller_title"}><img src={"/images/icons/leftnav/casino.png"} /> Explore Our Services</div>
            <Slider {...settings}>
                {news.map((item,index) => {
                    return (
                        <div className="slidebox">
                            <div className="row column">

                                <div className="tscroll_title"> {item.title}</div>
                                <div className="tscroll_desc">{item.desc}</div>
                                <div className={"row m8"}>
                                    <div className={"col-md-1 mobile_hide"}>
                                        <img className={"w35"} src={"/images/icons/bitcoin.png"} />
                                    </div>
                                    <div className={"col-md-11"}>
                                        <div className={"left_tp"}>
                                          {item.subtitle}
                                        </div>
                                        <div className={"left_bt"}>
                                            <span>  {item.subtitle2}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Threescroller;
