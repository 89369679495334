import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link} from "react-router-dom";
const Userscroller = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows:false,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 14,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
   let slides = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]

    return (
        <div className={"userscroll"}>
            <Slider {...settings}>
                {slides.map((item,index) => {
                    return (
                        <div className="slidebox">
                            <Link to={"/game/"+item}>


                            <div className="row column">
                                <div className="col-md-12 coin-alan ">
                                    <img className="coin-img" src={'/images/userscroll/'+item+'.webp'} alt="step-icon-1.png" />
                                </div>
                               <div className="scroll_username">playerx</div>
                                <div className="scroll_win up"> {item+12} USDT</div>

                            </div>
                            </Link>
                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Userscroller;
