import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../actions/general";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
const Leftnav = (props) => {
    const [cookies,setCookie] = useCookies();
   const [open,setOpen] = useState(0)
    const allData = useSelector((state) => state.general);
    const [subopen,setSubopen] = useState("0")
   const dispatch = useDispatch();
   const  openLeague = (id,e,title,image) => {
       e.preventDefault();
       if (cookies.lang) {
           dispatch(getLeagues(id, "league", title, image,cookies.lang));
       }
       else {
           dispatch(getLeagues(id, "league", title, image,"en"));
       }

   }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }
    const opensub = (id) => {

        setSubopen(id);
    }
     return (
        <div className={"left_nav"}>
            <div className={"bit_menu"}>
                <div className={"row m8"}>
                    <div className={"col-md-2  "}>
                        <img className={"w35"} src={"/images/icons/bitcoin.png"} />
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"left_tp"}>
                            Bitbet Coin BTT
                        </div>
                        <div className={"left_bt"}>
                            $0.118926 <span>-0.29%</span>
                        </div>
                    </div>

                </div>
                <div className={"mt20 row"}>
                    <div className={" col-md-12 flex"}>
                     <a href={"/trade/btt"}>  <button className={"btn btn-buy"}>Buy / Sell</button></a>
                        <a href={"/trade/btt"}>   <button className={"btn btn-danger"}>Dashboard</button></a>
                    </div>
                </div>

            </div>




            <div className={"widget_title_center"}> <span>{props.lang["main_menu"]}</span></div>


            <div onClick={()=>{openItems("casino")}} className={"widget_title"}>
                <img src={"/images/icons/leftnav/casino.png"} /> <span className={"widget_l"}>Casino</span>
                 <span className={"widget_r"}>8</span>
            </div>
            {open==='casino' && (
                 <div>
                <Link className={"left_sub"} to={"/games/slot"}><img src={"/images/cgames/slot.png"} /> Slot Games</Link>
                <Link className={"left_sub"} to={"/games/battle"}><img src={"/images/cgames/battle.png"} /> Battle Games</Link>
                <Link className={"left_sub"} to={"/games/roulette"}><img src={"/images/cgames/roulette.png"} /> Roulettes</Link>
                <Link className={"left_sub"} to={"/games/blackjack"}><img src={"/images/cgames/blackjack.png"} /> Blackjack</Link>
                <Link className={"left_sub"} to={"/games/challenge"}><img src={"/images/cgames/challenge.png"} /> Challenges</Link>
                <Link className={"left_sub"} to={"/games/card"}><img src={"/images/cgames/card.png"} /> Card Games</Link>
                <Link className={"left_sub"} to={"/games/latest"}><img src={"/images/cgames/latest.png"} /> Latest Games</Link>
                <Link className={"left_sub"} to={"/games/rollercoaster"}><img src={"/images/cgames/rollercoaster.png"} /> Rollercoaster</Link>

                 </div>

            )}

            <div onClick={()=>{openItems("sports")}} className={"widget_title"}><a href={"#"}><img src={"/images/icons/leftnav/sports.png"} />
                <span className={"widget_l"}>Sports</span><span className={"widget_r"}>9</span></a></div>
            {open==='sports' && (
                <div>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Soccer</a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/basketball.png"} /> Basketball</a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/golf.png"} /> Golf</a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/handball.png"} /> Handball</a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/ice-hockey.png"} /> Ice Hockey</a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/rugby.png"} /> Rugby </a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/tennis.png"} /> Tennis </a>
                    <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/volleyball.png"} /> Volleyball</a>

                </div>

            )}
            <div className={"widget_title"}><a href={"/nfts"}><img src={"/images/icons/leftnav/nft.png"} />
                <span className={"widget_l"}>NFT</span><span className={"widget_new"}>NEW</span></a></div>
            <div onClick={()=>{openItems("tennis")}} className={"widget_title"}><a href={"/trade/btt"}><img src={"/images/icons/leftnav/exchange.png"} /> <span className={"widget_l"}>Crypto Exchange</span><span className={"widget_r"}>8</span></a></div>
            <div onClick={()=>{openItems("other_sports")}} className={"widget_title"}><a href={"/staking"}><img src={"/images/icons/leftnav/stake.png"} /> <span className={"widget_l"}>Staking</span><span className={"widget_r"}>0</span></a></div>
            <div onClick={()=>{openItems("other_sports")}} className={"widget_title"}><a href={"/trade/btt"}><img src={"/images/icons/leftnav/suitcase.png"} /> <span className={"widget_l"}>Crypto Portfolio</span><span className={"widget_r"}>0</span></a></div>


            <div className={"widget_title_center"}> <span>BTT Coin</span></div>
            <div onClick={()=>{openItems("basketball")}} className={"widget_title"}>
                <a href={"/trade/btt"}><img src={"/images/icons/leftnav/dashboard.png"} />
                <span className={"widget_l"}>BTT Dashboard</span><span className={"widget_r"}></span>
            </a>
            </div>
            <div onClick={()=>{openItems("basketball")}} className={"widget_title"}>
                <a href={"/trade/btt"}><img src={"/images/icons/leftnav/trade.png"} />
                    <span className={"widget_l"}>Trade BTT</span><span className={"widget_r"}></span>
                </a>
            </div>
            <div className={"widget_title"}>
                <a href={"#"}><img src={"/images/icons/leftnav/wheel.png"} />
                    <span className={"widget_l"}>BTT Lottery</span><span className={"widget_new"}>NEW</span>
                </a>
            </div>

            <div className={"widget_title_center"}> <span>MORE</span></div>
            <div  className={"widget_title"}>
                <a href={"#"}><img src={"/images/icons/leftnav/jackpot.png"} />
                    <span className={"widget_l"}>Jackpot</span><span className={"widget_new"}>NEW</span>
                </a>
            </div>
            <div  className={"widget_title"}>
                <a href={"#"}><img src={"/images/icons/leftnav/trophy.png"} />
                    <span className={"widget_l"}>50K Race</span><span className={"widget_r"}></span>
                </a>
            </div>
            <div className={"widget_title"}>
                <a href={"#"}><img src={"/images/icons/leftnav/stream.png"} />
                    <span className={"widget_l"}>Streams / Videos</span><span className={"widget_r"}></span>
                </a>
            </div>

        </div>
    );
}
export  default Leftnav;
