import React, {useEffect} from "react";
import axios from "axios";
import {Link, useLocation, useParams} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import jwtDecode from "jwt-decode";
import {userDetails, userBalance, changeLanguage, getLeagues} from "../../actions/general";
import {setAuthorizationToken} from "../../helpers/setAuthorizationToken";
import {WEB_CONFIG} from "../../config/Static";
import {useDispatch, useSelector} from "react-redux";
import teams_ar from "../../lang/locales/team_ar.json";
import teams_en from "../../lang/locales/team_en.json";
const Header = (props) => {
    const location = useLocation().pathname;
    const lang = props.lang.messages;
    const dispatch = useDispatch();
    const [cookies,setCookie] = useCookies();
    const [balance, setBalance] = React.useState(0);
    const [account, setAccount] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [popup, setPopup] = React.useState(false);
    const [logstatus, setLogstatus] = React.useState("login");

    const [status, setStatus] = React.useState("none");
    const [userdata, setUserdata] = React.useState({
        "email":"",
        "password":""
    });
    const allData = useSelector((state) => state.general);

    const onSubmit = () => {

        axios.post(
            WEB_CONFIG.server+'/api/user/login',
            userdata,
            { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response =>{
                console.log(response.data);
                setMessage(response.data.message);

                if(response.data.success === true) {
                    setStatus('alert alert-success block');
                    let tok = jwtDecode(response.data.token);
                    localStorage.setItem("gsdhhrxchbhlkpkakd", response.data.token);
                    setAccount(response.data);
                    setAuthorizationToken(response.data.token);
                    const enc = Buffer.from(window.navigator.appVersion, 'utf8').toString('base64');
                    if(cookies) {}
                    setCookie(tok.cjwtpto, enc, {
                        path : '/',
                        sameSite:true
                    });

                    window.location.reload();
                }
                else {
                    setMessage(response.data.message)
                    setStatus('alert alert-danger block');
                }

            })
            .catch(error => {
                // console.log(error.data)
            });
    };
    const Logout = () => {
        localStorage.clear();
        window.location.reload();
    }
    function setForm(t,name) {
       //   console.log("t"); console.log(userdata);
          //console.log("name"); console.log(name);
        let key = name.toString()
        let val = t
        let obj  = {}
        obj[key] = val
        setUserdata({
            ...userdata,
            [key]:t.target.value
        })

         console.log("183 userdata");  console.log(userdata);

    }
    const  Auth = async () => {
        if (cookies.lang) {
            if(cookies.lang==="ar") {
                dispatch(changeLanguage(teams_ar,cookies.lang))
            }
            else {
                dispatch(changeLanguage(teams_en,cookies.lang))
            }
        }
        else {

            setCookie("lang", "en");
            dispatch(changeLanguage(teams_en,"en"))
        }
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            try {
                const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
                if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                    localStorage.clear();
                    return false;

                } else {
                    axios.post(
                        WEB_CONFIG.server+'/api/sport/balance',
                        jwt_Token_decoded,
                        { headers: { 'Content-Type': 'application/json' }}
                        )
                        .then(response =>{
                            setBalance(response.data.balance);
                            dispatch(userBalance(response.data.balance))
                           })
                 .catch(error => {
                     console.log(error.data)
                });
                    setAccount(jwt_Token_decoded);
                    dispatch(userDetails(jwt_Token_decoded))
                    return true;
                }
            } catch (err) {
                localStorage.clear();
                 //console.log(err);
                return false;
            }

        } else {
            localStorage.clear();
            //console.log("token yok");
            return false;
        }

    }
    useEffect(() => {
        Auth();
    }, [balance]);
   const loginStatus = (data) =>{
       if(data==="login") {
           setLogstatus("login");
       }
       else {
           setLogstatus("register");
       }
   }
    const popStatus = (data) =>{
        setPopup(popup !== true);
        if(data==="login") {
            setLogstatus("login");

        }
        else {
            setLogstatus("register");
        }
    }
    const changelang = (langid) => {
         setCookie("lang",langid)
       if(langid==="ar") {
           dispatch(changeLanguage(teams_ar,langid))
       }
       else {
           dispatch(changeLanguage(teams_en,langid))
       }

        if(location==="/live") {
            dispatch(getLeagues("live", "general", lang["latest_matches"], "WRL",langid));
        }
        else {
            dispatch(getLeagues("0", "general", lang["latest_matches"], "WRL",langid));
        }

    }

    return (
        <div className={"header"}>
            <div className={"container_full"}>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                     <a href={"/"}><img className={"logo_img"} src={"/images/system/logo.png"} /></a>
                    </div>
                    <div className={"col-md-5"}>
                        {popup && (
                            <div className={"pop_login"}>
                                <div onClick={()=>setPopup(false)} className={"close_popup"}>X</div>
                                <div onClick={()=>loginStatus("login")} className={logstatus==='login'?'login_title yellow':'login_title '}>Login</div>
                                <div onClick={()=>loginStatus("register")} className={logstatus==='register'?'login_title yellow':'login_title '}>Register</div>
                                {logstatus === "login" ? (
                                    <div className={"login_box row"}>

                                        <div className={"col-md-6"}>
                                            {message && (
                                                <div className={"mt-10 form_data"}>
                                                <label className={"user_warning centered checklabel"}>{message}</label>
                                                </div>
                                            )}
                                            <div className={"mt-10 form_data"}>
                                                <label className={"centered checklabel"}>Login to our user dashboard and start to win!</label>
                                            </div>
                                        <div className={"form_data"}>
                                            <label>Username</label>
                                            <input onChange={(e)=>setForm(e,"email")} type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Password</label>
                                            <input onChange={(e)=>setForm(e,"password")} type={"text"}/>
                                        </div>
                                            <div className={"mt-10 form_data row"}>
                                                <div className={"col-md-2"}>
                                                    <input className={"checkbox"} type={"checkbox"} />
                                                </div>
                                                <div className={"col-md-10"}>
                                                    <label className={"checklabel"}>Remember my password</label>
                                                </div>
                                            </div>
                                        <div className={"form_data"}>
                                            <button onClick={() => onSubmit()}
                                                    className={"btn btn-danger"}>{lang["login"]}</button>
                                        </div>

                                        </div>
                                        <div className={"col-md-6"}>
                                            <img className={"w100"} src={"/images/users/lgimg.png"} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={"login_box row"}>
                                        <div className={"col-md-6"}>

                                        <div className={"form_data"}>
                                            <label>First Name</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Last Name</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Email</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Phone</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Username</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <label>Password</label>
                                            <input type={"text"}/>
                                        </div>
                                        <div className={"form_data"}>
                                            <button onClick={() => onSubmit()}
                                                    className={"btn btn-danger"}>{lang["register"]}</button>
                                        </div>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <img className={"w100 regimg"} src={"/images/users/reg_right.png"} />
                                        </div>

                                    </div>
                                )
                                }


                            </div>
                        )}
                    </div>
                    <div className={"col-md-3 align-right"}>

                        {account.email ? (
                                <div className={"header_acc"}>
                                    <ul className={"user_top_nav"}>
                                        <li>
                                            <a href={"/user"}>
                                            <button className={"logout_btn btn btn-danger"}>
                                            <img className={"navicon"} src={"/images/users/account.png"} /> Account</button></a></li>
                                        <li>
                                            <button onClick={() => Logout()} className={"logout_btn btn btn-danger"}>
                                                <img className={"navicon"} src={"/images/users/logout.png"} /> {lang["logout"]}</button>
                                        </li>

                                    </ul>


                                </div>
                            ):(
                                <div className={"headerform"}>

                                    <button onClick={() => popStatus("login")} className={"btn btn-trans"}>{lang["login"]}</button>
                                    <button onClick={() => popStatus("register")} className={"btn btn-danger"}>{lang["register"]}</button>

                                </div>
                            )
                        }

                    </div>
                </div>

            </div>

        </div>

    );
}
export  default Header;
