import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link, useParams} from "react-router-dom";
const Gamedetail = (props) => {
    let params = useParams();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows:true,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
    const toggleFullScreen = () => {
        let el = document.getElementById("gamevid");
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        }
    };
    return (
        <div className={"threescroll casinoscroll gamecat gamedet"}>

            <div className={"no_margin row cat_header"}>
                <div className={"scroller_title"}>

                        <img src={"/images/cgames/latest.png"} />


                    Play {params.game} Now!
                </div>
                <div className={"gameorder"}>
                <div className="ttrade">

                    <img className={"full_img"} src={"/images/icons/like.png"} />
                    <img onClick={()=>toggleFullScreen()} className={"full_img"} src={"/images/icons/fullscreen.png"} />
                </div>
                </div>
               <div className={"login_please"}>
                   <div className={"login_mes"}>Please log in to play game</div>
               </div>
                <div className={"game_detail"}>
                    <video id={"gamevid"} width="100%" height="500" loop autoPlay muted  >
                        <source src="/images/cgames/game.mp4" type="video/mp4"/>
                    </video>
                </div>



            </div>


        </div>

    );
}
export  default Gamedetail;
