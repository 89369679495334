import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link, useParams} from "react-router-dom";
const Gamescategory = (props) => {
    let params = useParams();
   // console.log(params);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows:true,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

    return (
        <div className={"threescroll casinoscroll gamecat"}>
            <div className={"gamespills"}>
                <Link className={"left_sub"} to={"/games/slot"}><img src={"/images/cgames/slot.png"} /> Slots</Link>
                <Link className={"left_sub"} to={"/games/battle"}><img src={"/images/cgames/battle.png"} /> Battles</Link>
                <Link className={"left_sub"} to={"/games/roulette"}><img src={"/images/cgames/roulette.png"} /> Roulettes</Link>
                <Link className={"left_sub"} to={"/games/blackjack"}><img src={"/images/cgames/blackjack.png"} /> Blackjack</Link>
                <Link className={"left_sub"} to={"/games/challenge"}><img src={"/images/cgames/challenge.png"} /> Challenges</Link>
                <Link className={"left_sub"} to={"/games/card"}><img src={"/images/cgames/card.png"} /> Card Games</Link>

            </div>
            <div className={"no_margin row cat_header"}>
                <div className={"scroller_title"}><img src={"/images/cgames/"+params.game+".png"} /> {params.game} Games
                </div>
                <div className={"gameorder"}>
                    <div className="ttrade">Sort By:</div>
                    <select className={"order-select"}>
                        <option>Popular Games</option>
                        <option>Alphabetical</option>
                        <option>New Games</option>
                    </select>
                </div>


            </div>
             <div className={"row"}>
                {slides.map((item,index) => {
                    return (
                        <div className="col-md-3 col-sm-6 gamecover">
                            <a href={"/game/"+params.game}>


                            <div className="row column">
                                <div className="col-md-12 coin-alan ">
                                    <img className="coin-img" src={'/images/userscroll/'+item+'.webp'} alt="step-icon-1.png" />
                                </div>
                            </div>
                            </a>
                        </div>

                    )
                })}

             </div>

        </div>

    );
}
export  default Gamescategory;
