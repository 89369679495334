
import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Gamesscroller = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows:true,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };
    let slides = [
        {
            id:1,
            type:"Football",
            image:"soccer.png"
        },{
            id:2,
            type:"Basketball",
            image:"basketball.png"
        },
        {
            id:3,
            type:"Golf",
            image:"golf.png"
        },
        {
            id:4,
            type:"Handball",
            image:"handball.png"
        },
        {
            id:5,
            type:"Ice Hockey",
            image:"ice-hockey.png"
        },
        {
            id:6,
            type:"Rugby",
            image:"rugby.png"
        }
        ,
        {
            id:6,
            type:"Tennis",
            image:"tennis.png"
        } ,
        {
            id:6,
            type:"Volleyball",
            image:"volleyball.png"
        }
    ]

    return (
        <div className={"threescroll gamescroll"}>
            <div className={"no_margin row"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/casino.png"} /> SPORTBOOK
                </div>

                <div className={"ttrade"}>All Sports</div>
            </div>
            <Slider {...settings}>
                {slides.map((item,index) => {
                    return (
                        <div className="slidebox">
                        <a href={"/sports"}>
                            <div className="row column">
                                <div className="gscroll_img">
                                    <img className="coin-img" src={'/images/sports/'+item.image} alt="step-icon-1.png" />
                                </div>
                                <div className={"gscroll_title"}>{item.type}</div>


                            </div>
                            </a>
                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Gamesscroller;
