import React, {useEffect, useState} from "react";
import Leftnav from "./Leftnav";
import Buletin from "./Buletin";
import Slip from "./Slip";
import {Leaguesjson} from "../../config/Api";
import {searchmatch,getLeftleagues} from "../../actions/general";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
const Homesports = (props) => {
    const [cookies,setCookie] = useCookies();
    const dispatch = useDispatch();
    const [leagues, setLeagues] = useState([]);
    const [left, setLeft] = useState("closed");
    const [right, setRight] = useState("closed");

    function openLeft() {
        if(left==="closed") {
            setLeft("opened");
        }
        else {
            setLeft("closed");
        }
    }
    function openRight() {
        if(right==="closed") {
            setRight("opened");
        }
        else {
            setRight("closed");
        }
    }
    const getLeagues = async () => {
       // dispatch(getLeftleagues(cookies.lang));
    }
    useEffect(() => {
        getLeagues();
    }, [leagues]);

    return (
        <div className={"home_pages matches"}>

            <div onClick={()=>openLeft()} className={"left_open"}><img src={"/images/icons/navleft.png"} /></div>
            <div onClick={()=>openRight()} className={"right_open"}><img src={"/images/icons/nav_open.png"} /></div>
            <div className={"row"}>
                <div className={"col-md-2 left_hide "+left}>
                    <div onClick={()=>openLeft()} className={"left_open"}><img src={"/images/icons/navleft.png"} /></div>
                    <Leftnav lang={props.lang.messages} leagues={leagues}/>
                </div>
                <div className={"col-md-8 pr5 col-sm-12 col-xs-12"}>
                    <Buletin lang={props.lang.messages} />
                </div>
                <div className={"pl0 col-md-2 col-sm-6 right_hide "+right}>
                    <div onClick={()=>openRight()} className={"right_open"}><img src={"/images/icons/nav_open.png"} /></div>
                    <Slip lang={props.lang.messages}/>
                </div>
            </div>
        </div>
    );
}
export  default Homesports;
