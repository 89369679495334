import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link, useParams} from "react-router-dom";
const Nftcategory = (props) => {

    let slides = [

        {
            id:1,
            type:"Rollbot #1",
            price:"50",
            image:"1.webp"
        },
        {
            id:2,
            type:"Sport Rollbot #21",
            price:"47",
            image:"2.webp"
        },
        {
            id:3,
            type:"Bored Ape #1",
            price:"25",
            image:"3.avif"
        },
        {
            id:4,
            type:"Superlative Ape #1",
            price:"98",
            image:"4.avif"
        },
        {
            id:5,
            type:"League of Legends",
            price:"20",
            image:"5.webp"
        },
        {
            id:6,
            type:"Invisible Friends",
            price:"45",
            image:"6.webp"
        },
        {
            id:7,
            type:"Superlative Ape #20",
            price:"42",
            image:"7.avif"
        }
    ]

    return (
        <div className={"threescroll casinoscroll gamecat"}>
            <div className={"gamespills"}>
                <Link className={"left_sub"} to={"/games/slot"}><img src={"/images/cgames/slot.png"} /> Lobby</Link>
                <Link className={"left_sub"} to={"/games/battle"}><img src={"/images/cgames/battle.png"} /> New Nft's</Link>
                <Link className={"left_sub"} to={"/games/roulette"}><img src={"/images/cgames/roulette.png"} /> Our Nft's</Link>


            </div>
            <div className={"no_margin row cat_header"}>
                <div className={"scroller_title"}><img src={"/images/icons/leftnav/nft.png"} /> NFT's
                </div>
                <div className={"gameorder"}>
                    <div className="ttrade">Sort By:</div>
                    <select className={"order-select"}>
                        <option>Popular Nft's</option>
                        <option>Alphabetical</option>
                        <option>New Nft's</option>
                    </select>
                </div>


            </div>
            <div className={"row"}>
                {slides.map((item,index) => {
                    return (
                        <div className="col-md-4 slidebox mb-20 col-sm-6">
                            <div className="row column">
                                <div className={"row m8"}>
                                    <div className={"col-md-12"}>
                                   <a
                                       href={"/nfT/"+item.id}
                                      >   <img className={"nftimg"} src={"/images/nfts/"+item.image} /></a>
                                    </div>


                                </div>
                            </div>
                            <div className={"exvolume"}>{item.type}</div>
                            <div className={"excenter "}>

                                <button className=" w40 mt-20 btn buy-btn">BUY</button>
                                <button className=" w40 mt-20 btn btn-danger">BET</button>
                            </div>

                        </div>

                    )
                })}

            </div>

        </div>

    );
}
export  default Nftcategory;
