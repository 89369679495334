import React from 'react';
import { Switch, Route  } from 'react-router-dom';
import Home from "./home";
import {connect, useSelector} from "react-redux";
import AppLocale from "../lang";
import User from "./User";
import Password from "./password";
import Sportbets from "./sportbets";
import Account from "./account";
import Accountperiods from "./accountperiods";
import Members from "./members";
import Coupondetail from "./coupon_detail";
import Live from "./live";
import Register from "./register";
import Dil from "./dil";
import Ligler from "./ligler";
import Games from "./games";
import Game from "./game";
import Nfts from "./Nfts";
import Nft from "./Nft";
import Trade from "./Trade";
import Sports from "./Sports";
import Staking from "./Staking";
const Index = (props) => {
  const {locale } = props;
  const allData = useSelector((state) => state.general);
  const currentAppLocale = AppLocale[allData.locale];

  return (
    <>

        <Switch>
          <Route exact path="/">
            <Home {...currentAppLocale} />
          </Route>
          <Route exact path="/sports">
            <Sports {...currentAppLocale} />
          </Route>
          <Route exact path="/staking">
            <Staking {...currentAppLocale} />
          </Route>
          <Route exact path="/games/:game">
            <Games {...currentAppLocale} />
          </Route>
          <Route exact path="/trade/:coin">
            <Trade {...currentAppLocale} />
          </Route>
          <Route exact path="/nfts/">
            <Nfts {...currentAppLocale} />
          </Route>
          <Route exact path="/nft/:item">
            <Nft {...currentAppLocale} />
          </Route>
          <Route exact path="/game/:game">
            <Game {...currentAppLocale} />
          </Route>
          <Route exact path="/live">
            <Live {...currentAppLocale} />
          </Route>
          <Route exact path="/user">
            <User {...currentAppLocale} />
          </Route>
          <Route exact path="/password">
            <Password {...currentAppLocale} />
          </Route>
          <Route exact path="/sportbets">
            <Sportbets {...currentAppLocale} />
          </Route>
          <Route exact path="/account">
            <Account {...currentAppLocale} />
          </Route>
          <Route exact path="/accountperiods">
            <Accountperiods {...currentAppLocale} />
          </Route>
          <Route exact path="/members">
            <Members {...currentAppLocale} />
          </Route>
          <Route exact path="/dil">
            <Dil {...currentAppLocale} />
          </Route>
          <Route exact path="/ligler">
            <Ligler {...currentAppLocale} />
          </Route>
          <Route path="/coupon_detail/:id">
            <Coupondetail {...currentAppLocale}/>
          </Route>
        
        </Switch>

    </>
  );
}
const mapStateToProps = ({ theme }) => {
  const {locale } = theme;
  return {locale};
};

export default connect(mapStateToProps, {  })(Index);
