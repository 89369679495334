import React, {useState} from 'react';
import Header from '../components/site/Header';
import {useSelector} from "react-redux";
import Homesports from "../components/site/Homesports";
export default function Sports (currentAppLocale) {
    const allData = useSelector((state) => state.general);
    const [cpp, setCpp] = useState([]);
    return (
        <>
            <div className="main mains sportmain">
                <Header lang={currentAppLocale} />
                <Homesports lang={currentAppLocale} />

            </div>
            <div className={"p_coupon"}>
                {
                    allData.maincoupon && (


                        <div className={"grey_box"}>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}>{currentAppLocale.messages["player"]} :</div>
                                <div className={"col-md-6 align_right"}>{allData.maincoupon.user_id}</div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}> {currentAppLocale.messages["ticket_no"]} :</div>
                                <div className={"col-md-6 align_right"}>{allData.maincoupon.coupon_id}</div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}> {currentAppLocale.messages["date"]} :</div>
                                <div className={"col-md-6 align_right"}>{allData.maincoupon.date}}</div>
                            </div>
                        </div>
                    )
                }

                { allData.maincoupon &&
                allData.odddata.map((coupon,index) => {
                    return (
                        <div className={"white_box"}>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}>{currentAppLocale.messages["football"]} :</div>
                                <div className={"col-md-6 align_right"}>{coupon.date}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}>{coupon.first} - {coupon.second}</div>
                                <div className={"col-md-6 align_right"}> </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-6 left"}> {currentAppLocale.messages["guess"]}: {coupon.selected}</div>
                                <div className={"col-md-6 align_right"}>{coupon.odd}</div>
                            </div>
                        </div>
                    )
                })  }
                <div className={"grey_box"}>
                    <div className={"row"}>
                        <div className={"col-md-6 left"}> {currentAppLocale.messages["amount"]} :	</div>
                        <div className={"col-md-6 align_right"}>{allData.maincoupon.price}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6 left"}> {currentAppLocale.messages["odds"]} :</div>
                        <div className={"col-md-6 align_right"}>{allData.maincoupon.total_amount}</div>


                    </div>
                    <div className={"row"}>
                        <div className={"bigp"}>DNR {allData.maincoupon.total}</div>
                    </div>

                </div>
            </div>
        </>
    );

}
