import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../actions/general";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
const Leftnav = (props) => {
    const [cookies,setCookie] = useCookies();
    const lang = props.lang.messages;
    const [open,setOpen] = useState(0)
    const allData = useSelector((state) => state.general);
    const [subopen,setSubopen] = useState("0")
    const dispatch = useDispatch();
    const  openLeague = (id,e,title,image) => {
        e.preventDefault();
        if (cookies.lang) {
            dispatch(getLeagues(id, "league", title, image,cookies.lang));
        }
        else {
            dispatch(getLeagues(id, "league", title, image,"en"));
        }

    }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }
    const opensub = (id) => {

        setSubopen(id);
    }
    return (
        <div className={"left_nav"}>
            <div className={"bit_menu"}>
                <div className={"row m8"}>
                </div>
                <div className={"mt20 row"}>
                    <div className={" col-md-12 "}>

                        <a href={"/user"}>   <button className={"w100 btn btn-danger"}>User Dashboard</button></a>
                    </div>
                </div>
            </div>
            <div className={"widget_title_center"}> <span>{props.lang["main_menu"]}</span></div>
            <div className={"widget_title"}>
                <a href={"/user"}><img src={"/images/users/account_white.png"} />
                <span className={"widget_l"}>{lang["user_info"]}</span>
                </a>
                <a href={"/password"}><img src={"/images/users/lock.png"} />
                    <span className={"widget_l"}>{lang["change_pass"]}</span>
                </a>
                <a href={"/sportbets"}><img src={"/images/users/tracking.png"} />
                    <span className={"widget_l"}>{lang["sportbets"]}</span>
                </a>

                <a href={"/account"}><img src={"/images/users/profit.png"} />
                    <span className={"widget_l"}>{lang["account"]}</span>
                </a>

                <a href={"/accountperiods"}><img src={"/images/users/user.png"} />
                    <span className={"widget_l"}>{lang["accountperiods"]}</span>
                </a>

                <a href={"/members"}><img src={"/images/users/account_white.png"} />
                    <span className={"widget_l"}>{lang["members"]}</span>
                </a>
            </div>

        </div>
    );
}
export  default Leftnav;
