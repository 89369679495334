import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Homeslider = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows:false,
        autoplay:false,
        autoplaySpeed:2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    let slides = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]

    return (
        <div className={"homescroll"}>
            <Slider {...settings}>
                {slides.map((item,index) => {
                    return (
                        <div className="homeslidebox">
                            <div className="row column">
                                <div className="homeslideimg">
                                    <img src={'images/slider/2.png'} alt="step-icon-1.png" />
                                </div>
                                <div className={"titlebox"}>
                                <div className="home_title">Welcome to Bitbet</div>
                                <div className="home_desc"> Play All champions leagues matches with best odds and with crypto currencies...</div>
                                    <button className="btn btn-danger">Register Now</button>
                                </div>
                                <div className={"bitlogo"}>
                                    <img src={'images/system/logo.png'} />
                                </div>

                            </div>
                        </div>

                    )
                })}


            </Slider>
        </div>

    );
}
export  default Homeslider;
