import React, {useEffect, useState} from "react";
import axios from "axios";
import Web3 from 'web3';
import {Link, useParams} from "react-router-dom";
const Stakecategory = (props) => {
    let params = useParams();
    const [network, setNetwork] = useState({ id: '0', name: 'none' });
    let coins = [
        {
            id:1,
            type:"BTC",
            price:"25.254.20",
            status:"high",
            apy:5
        },
        {
            id:2,
            type:"ETH",
            price:"1.593.12",
            status:"low",
            apy:8
        },
        {
            id:3,
            type:"BNB",
            price:"212.85",
            status:"high",
            apy:12
        },
        {
            id:4,
            type:"APT",
            price:"5.29",
            status:"high",
            apy:17
        },
        {
            id:5,
            type:"BCH",
            price:"216.254",
            status:"low",
            apy:9
        },
        {
            id:6,
            type:"ADA",
            price:"0.25",
            status:"high",
            apy:13
        },
        {
            id:7,
            type:"APE",
            price:"1.1189",
            status:"low",
            apy:25
        }
        ,
        {
            id:8,
            type:"ARB",
            price:"0.8295",
            status:"high",
            apy:36
        }
        ,
        {
            id:9,
            type:"DOGE",
            price:"0.0606",
            status:"high",
            apy:7
        }
        ,
        {
            id:10,
            type:"LINK",
            price:"7.338",
            status:"low",
            apy:18
        }
        ,
        {
            id:11,
            type:"LTC",
            price:"63.884",
            status:"low",
            apy:21
        }
        ,
        {
            id:12,
            type:"TRX",
            price:"0.084",
            status:"high",
            apy:23
        }
        ,
        {
            id:13,
            type:"UNIBOT",
            price:"54.883",
            status:"high",
            apy:14
        }
    ]
    const fetchDataFromBlockchain = async (pdata) => {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            window.web3 = new Web3(window.ethereum);

        }
    };

    const connectHandler = async () => {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' })
            window.web3 = new Web3(window.ethereum);
            let web3 = window.web3;
        }
    }
    return (
        <div className={"threescroll casinoscroll coindetail"}>
            <div className={"gamespills"}>
                <Link className={"left_sub"} to={"/trade/BTT"}><img src={"/images/icons/leftnav/dashboard.png"} /> Trade</Link>
                <Link className={"left_sub"} to={"/staking"}><img src={"/images/icons/leftnav/stake.png"} /> Staking</Link>
                <Link className={"left_sub"} to={"#"}><img src={"/images/icons/leftnav/suitcase.png"} /> My Wallet</Link>


            </div>

            <div className={" row cat_header"}>



                <div className={"chart_area w100 row"}>



                    {coins.map((item,index) => {
                        return (

                    <div className={" col-md-4 col-sm-12"}>
                        <div className={"stake_box"}>

                            <div className={"row"}>
                                <div className={"stake_title"}>
                                    <div className={"stake_first row"}>
                                        <div className={"st_icon col-md-1 no_padding"}> <img className=" w35" src={"/images/coins/"+item.type+".png"} /></div>
                                        <div className={"st_title col-md-6"}>STAKE {item.type}</div>
                                        <div className={"st_apy col-md-5"}>Earn {item.apy}% FLEXIBLE  APY </div>
                                    </div>
                                    <div className={"st_more"}>Stake, Earn – And more! </div>

                                </div>

                            </div>
                            <div className={"pr_input_data"}><div>Stake Amount</div></div>
                            <div className={"token_input"}>
                                <img className="tinp_img w35" src={"/images/coins/"+item.type+".png"} />
                                <input type={"text"} className={"token_i"}/>
                                <button className={"token_max"}>MAX</button>
                            </div>

                            <div className={"cost_est"}>
                                <div className={"tk_cost"}>Total Staked: {item.apy*124578}</div>
                                <div className={"tk_est"}>Total Locked: 5.644,898</div>
                                <div className={"tk_est"}>Average Lock Duration: 23 days</div>
                            </div>
                            <button onClick={()=>   connectHandler()  }  className=" w100 mt-20 btn tbt stake-btn">
                                <img className="button_icon w35" src="/images/icons/metamask.png" /> LOGIN TO CONTINUE
                            </button>
                        </div>
                    </div>

                        )
                    })}
                </div>



            </div>


        </div>

    );
}
export  default Stakecategory;
